.new-styles,
.new-styles * {	
	margin: 0;
	padding: 0;
	border: none;
	box-sizing: border-box;
	outline: none;
}
.new-styles {
   font-family: 'Roboto', sans-serif;

  font-weight: 300;
  line-height: 1.4;
  letter-spacing: 0em;  
  color: #646464;

  position: relative;
  font-size: 16px;

  @media screen and (max-width: 1600px) { position: relative; }   
  @media screen and (max-width: 1280px) { position: relative; }  
  @media screen and (max-width: 1200px) { position: relative; }  
  @media screen and (max-width: 1100px) { position: relative; }  
  @media screen and (max-width: 960px) { position: relative; }
  @media screen and (max-width: 670px) { position: relative; }  

  @media screen and (min-width: 961px) { position: relative; }
}
.new-styles.header {
  padding-bottom: 50px;

  @media screen and (max-width: 960px) {
    padding-bottom: 30px;
  }
}

