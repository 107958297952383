.new-styles {
  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -16px;
    margin-right: -16px;
    position: relative;

    @media screen and (max-width: 670px) {
      margin-left: -7px;
      margin-right: -7px;
    }

    &--center {
      justify-content: center;
    }
    &--start {
      justify-content: flex-start;
    }
    &--null {
      margin-left: 0;
      margin-right: 0;

      @media screen and (max-width: 670px) {
        margin-left: 0;
        margin-right: 0;
      }
    }
    &--small {
      margin-left: -7px;
      margin-right: -7px;
    }
  }
  .col {
    padding-left: 16px;
    padding-right: 16px;

    @media screen and (max-width: 670px) {
      padding-left: 7px;
      padding-right: 7px;
    }
  }
  .row--small .col {
    padding-left: 7px;
    padding-right: 7px;
  }
  .row--null .col {
    padding-left: 0;
    padding-right: 0;
  }
  .col-1 {
    width: 8.33%;

    @media screen and (max-width: 670px) {
      width: 100%;
    }
  }
  .col-2 {
    width: 16.66%;

    @media screen and (max-width: 670px) {
      width: 100%;
    }
  }
  .col-3 {
    width: 25%;

    @media screen and (max-width: 670px) {
      width: 100%;
    }
  }
  .col-4 {
    width: 33.33%;

    @media screen and (max-width: 670px) {
      width: 100%;
    }
  }
  .col-5 {
    width: 41.66%;

    @media screen and (max-width: 670px) {
      width: 100%;
    }
  }
  .col-6 {
    width: 50%;

    @media screen and (max-width: 670px) {
      width: 100%;
    }
  }
  .col-7 {
    width: 58.33%;

    @media screen and (max-width: 670px) {
      width: 100%;
    }
  }
  .col-8 {
    width: 66.66%;

    @media screen and (max-width: 670px) {
      width: 100%;
    }
  }
  .col-9 {
    width: 75%;

    @media screen and (max-width: 670px) {
      width: 100%;
    }
  }
  .col-10 {
    width: 83.33%;

    @media screen and (max-width: 670px) {
      width: 100%;
    }
  }
  .col-11 {
    width: 91.66%;

    @media screen and (max-width: 670px) {
      width: 100%;
    }
  }
  .col-12 {
    width: 100%;
  }
  @media screen and (max-width: 1200px) {
    .col-lg-6 {
      width: 50%;
    }
    .col-lg-12 {
      width: 100%;
    }
  }
  @media screen and (max-width: 960px) {
    .col-md-1 {
      width: 8.33%;
    }
    .col-md-2 {
      width: 16.66%;
    }
    .col-md-3 {
      width: 25%;
    }
    .col-md-4 {
      width: 33.33%;
    }
    .col-md-5 {
      width: 41.66%;
    }
    .col-md-6 {
      width: 50%;
    }
    .col-md-7 {
      width: 58.33%;
    }
    .col-md-8 {
      width: 66.66%;
    }
    .col-md-9 {
      width: 75%;
    }
    .col-md-10 {
      width: 83.33%;
    }
    .col-md-12 {
      width: 100%;
    }
  }
  @media screen and (max-width: 670px) {
    .col-sm-6 {
      width: 50%;
    }
    .col-sm-12 {
      width: 100%;
    }
  }
}