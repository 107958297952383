@font-face {
  font-family: 'RussoOne';
  src: url('../fonts/russoone.woff2') format('woff2'), url('../fonts/russoone.woff') format('woff'), url('../fonts/russoone.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
.new-styles,
.new-styles * {
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
  outline: none;
}
.new-styles {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: 0em;
  color: #646464;
  position: relative;
  font-size: 16px;
}
@media screen and (max-width: 1600px) {
  .new-styles {
    position: relative;
  }
}
@media screen and (max-width: 1280px) {
  .new-styles {
    position: relative;
  }
}
@media screen and (max-width: 1200px) {
  .new-styles {
    position: relative;
  }
}
@media screen and (max-width: 1100px) {
  .new-styles {
    position: relative;
  }
}
@media screen and (max-width: 960px) {
  .new-styles {
    position: relative;
  }
}
@media screen and (max-width: 670px) {
  .new-styles {
    position: relative;
  }
}
@media screen and (min-width: 961px) {
  .new-styles {
    position: relative;
  }
}
.new-styles.header {
  padding-bottom: 50px;
}
@media screen and (max-width: 960px) {
  .new-styles.header {
    padding-bottom: 30px;
  }
}
.new-styles .inner {
  position: relative;
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 30px;
}
@media screen and (max-width: 670px) {
  .new-styles .inner {
    padding: 0 15px;
  }
}
.new-styles .inner:after {
  content: '';
  display: block;
  clear: both;
}
.new-styles .inner--all {
  padding: 0;
  overflow: hidden;
}
@media screen and (max-width: 960px) {
  .new-styles .inner--all {
    padding: 0;
  }
}
@media screen and (max-width: 670px) {
  .new-styles .inner--all {
    padding: 0;
  }
}
.new-styles .inner--max {
  padding: 0 60px;
}
@media screen and (max-width: 1600px) {
  .new-styles .inner--max {
    padding: 0 30px;
  }
}
@media screen and (max-width: 670px) {
  .new-styles .inner--max {
    padding: 0 15px;
  }
}
.new-styles .inner--large {
  padding: 0 60px;
  max-width: 1920px;
}
@media screen and (max-width: 1600px) {
  .new-styles .inner--large {
    padding: 0 30px;
  }
}
@media screen and (max-width: 670px) {
  .new-styles .inner--large {
    padding: 0 15px;
  }
}
.new-styles .inner--container {
  max-width: 1340px;
  padding: 0 30px;
}
@media screen and (max-width: 670px) {
  .new-styles .inner--container {
    padding: 0 15px;
  }
}
.new-styles .inner--allright {
  position: relative;
  max-width: 100%;
  height: 100%;
  margin: 0 0 0 auto;
  padding: 0 0 0 25px;
  width: calc(1310px + (100vw - 1310px)/2);
  overflow: hidden;
}
@media screen and (max-width: 670px) {
  .new-styles .inner--allright {
    padding-left: 15px;
    padding-right: 40px;
  }
}
.new-styles .inner--allright--inside {
  max-width: 1280px;
  overflow: visible;
}
.new-styles .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -16px;
  margin-right: -16px;
  position: relative;
}
@media screen and (max-width: 670px) {
  .new-styles .row {
    margin-left: -7px;
    margin-right: -7px;
  }
}
.new-styles .row--center {
  justify-content: center;
}
.new-styles .row--start {
  justify-content: flex-start;
}
.new-styles .row--null {
  margin-left: 0;
  margin-right: 0;
}
@media screen and (max-width: 670px) {
  .new-styles .row--null {
    margin-left: 0;
    margin-right: 0;
  }
}
.new-styles .row--small {
  margin-left: -7px;
  margin-right: -7px;
}
.new-styles .col {
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (max-width: 670px) {
  .new-styles .col {
    padding-left: 7px;
    padding-right: 7px;
  }
}
.new-styles .row--small .col {
  padding-left: 7px;
  padding-right: 7px;
}
.new-styles .row--null .col {
  padding-left: 0;
  padding-right: 0;
}
.new-styles .col-1 {
  width: 8.33%;
}
@media screen and (max-width: 670px) {
  .new-styles .col-1 {
    width: 100%;
  }
}
.new-styles .col-2 {
  width: 16.66%;
}
@media screen and (max-width: 670px) {
  .new-styles .col-2 {
    width: 100%;
  }
}
.new-styles .col-3 {
  width: 25%;
}
@media screen and (max-width: 670px) {
  .new-styles .col-3 {
    width: 100%;
  }
}
.new-styles .col-4 {
  width: 33.33%;
}
@media screen and (max-width: 670px) {
  .new-styles .col-4 {
    width: 100%;
  }
}
.new-styles .col-5 {
  width: 41.66%;
}
@media screen and (max-width: 670px) {
  .new-styles .col-5 {
    width: 100%;
  }
}
.new-styles .col-6 {
  width: 50%;
}
@media screen and (max-width: 670px) {
  .new-styles .col-6 {
    width: 100%;
  }
}
.new-styles .col-7 {
  width: 58.33%;
}
@media screen and (max-width: 670px) {
  .new-styles .col-7 {
    width: 100%;
  }
}
.new-styles .col-8 {
  width: 66.66%;
}
@media screen and (max-width: 670px) {
  .new-styles .col-8 {
    width: 100%;
  }
}
.new-styles .col-9 {
  width: 75%;
}
@media screen and (max-width: 670px) {
  .new-styles .col-9 {
    width: 100%;
  }
}
.new-styles .col-10 {
  width: 83.33%;
}
@media screen and (max-width: 670px) {
  .new-styles .col-10 {
    width: 100%;
  }
}
.new-styles .col-11 {
  width: 91.66%;
}
@media screen and (max-width: 670px) {
  .new-styles .col-11 {
    width: 100%;
  }
}
.new-styles .col-12 {
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .new-styles .col-lg-6 {
    width: 50%;
  }
  .new-styles .col-lg-12 {
    width: 100%;
  }
}
@media screen and (max-width: 960px) {
  .new-styles .col-md-1 {
    width: 8.33%;
  }
  .new-styles .col-md-2 {
    width: 16.66%;
  }
  .new-styles .col-md-3 {
    width: 25%;
  }
  .new-styles .col-md-4 {
    width: 33.33%;
  }
  .new-styles .col-md-5 {
    width: 41.66%;
  }
  .new-styles .col-md-6 {
    width: 50%;
  }
  .new-styles .col-md-7 {
    width: 58.33%;
  }
  .new-styles .col-md-8 {
    width: 66.66%;
  }
  .new-styles .col-md-9 {
    width: 75%;
  }
  .new-styles .col-md-10 {
    width: 83.33%;
  }
  .new-styles .col-md-12 {
    width: 100%;
  }
}
@media screen and (max-width: 670px) {
  .new-styles .col-sm-6 {
    width: 50%;
  }
  .new-styles .col-sm-12 {
    width: 100%;
  }
}
.burger {
  display: none;
}
@media screen and (max-width: 960px) {
  .burger {
    width: 30px;
    height: 30px;
    display: block;
    background: url(../images/burger.svg) no-repeat center, #ffffff;
    background-size: 13px 10px;
  }
  .burger--open {
    background: url(../images/burger-close.svg) no-repeat center, #ffffff;
    background-size: 13px 13px;
  }
}
.top-line {
  background-color: #0068a0;
}
@media screen and (max-width: 960px) {
  .top-line {
    display: none;
  }
}
.top-line__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 0;
  align-content: center;
  align-items: center;
}
.top-line__main {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}
.top-line__item {
  color: #ffffff;
  font-size: 14px;
}
.top-line__item--venue {
  padding: 0 40px 0 23px;
  position: relative;
}
.top-line__item--venue:before {
  content: '';
  display: block;
  width: 15px;
  height: 18px;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../images/venue-icon-blue.svg) no-repeat center;
  background-size: contain;
}
.top-line__item--venue:hover {
  color: #ffffff;
}
.top-line__item--mail {
  padding: 0 45px 0 28px;
  position: relative;
  text-decoration: none;
}
.top-line__item--mail:before {
  content: '';
  display: block;
  width: 19px;
  height: 14px;
  position: absolute;
  top: 4px;
  left: 0;
  background: url(../images/mail-icon-blue.svg) no-repeat center;
  background-size: contain;
}
.top-line__contact-btn {
  font-family: 'RussoOne', sans-serif;
  font-size: 10px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #76beea;
  position: relative;
  text-decoration: none;
  padding-right: 28px;
}
.top-line__contact-btn:after {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 0;
  background: url(../images/arrow-top-contact-white.svg) no-repeat center, #76beea;
}
.top-line__contact-btn--text {
  position: relative;
  display: inline-block;
  padding-bottom: 5px;
}
.top-line__contact-btn--text:after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: #76beea;
  position: absolute;
  bottom: 0;
  left: 0;
}
.top-line__contact-btn:hover {
  color: #76beea;
}
.top-info {
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  z-index: 999;
  position: relative;
}
@media screen and (max-width: 960px) {
  .top-info {
    box-shadow: none;
    background: #0068a0;
  }
}
.top-info__container,
.top-info__left,
.top-info__right {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}
.top-info__left {
  justify-content: flex-start;
}
@media screen and (max-width: 960px) {
  .top-info__left {
    max-width: 40%;
  }
}
.top-info__right {
  justify-content: flex-end;
}
@media screen and (max-width: 960px) {
  .top-info__right {
    max-width: 60%;
  }
}
.top-info__logo {
  margin-right: 75px;
}
@media screen and (max-width: 1600px) {
  .top-info__logo {
    margin-right: 30px;
  }
}
@media screen and (max-width: 1100px) {
  .top-info__logo {
    max-width: 130px;
  }
}
@media screen and (max-width: 960px) {
  .top-info__logo {
    margin-right: 15px;
    padding-top: 5px;
    padding-bottom: 10px;
    max-width: 100%;
  }
}
.top-info__logo--white {
  display: none;
}
@media screen and (max-width: 960px) {
  .top-info__logo--white {
    display: block;
    max-width: 100%;
  }
}
@media screen and (max-width: 960px) {
  .top-info__logo--black {
    display: none;
    max-width: 100%;
  }
}
.top-info__main-data {
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-end;
}
@media screen and (max-width: 1280px) {
  .top-info__main-data {
    margin-right: 0;
  }
}
@media screen and (max-width: 960px) {
  .top-info__main-data {
    margin-right: 10px;
  }
}
.top-info__phone {
  font-size: 22px !important;
  font-weight: 500 !important;
  padding-left: 23px;
  position: relative;
  text-decoration: none;
  color: #1e1e1e !important;
  font-family: 'Roboto', sans-serif;
}
@media screen and (max-width: 1600px) {
  .top-info__phone {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 960px) {
  .top-info__phone {
    color: #ffffff !important;
    font-size: 14px !important;
    padding-left: 13px;
  }
}
.top-info__phone:before {
  content: '';
  display: block;
  width: 11px;
  height: 20px;
  background: url(../images/phone-icon-blue.png);
  background-size: contain;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
@media screen and (max-width: 960px) {
  .top-info__phone:before {
    width: 7px;
    height: 12px;
  }
}
.top-info__city {
  font-size: 13px;
}
@media screen and (max-width: 960px) {
  .top-info__city {
    color: #ffffff;
  }
}
@media screen and (max-width: 1280px) {
  .top-info__btn-block {
    display: none;
  }
}
.top-info__btn {
  font-size: 12px;
  letter-spacing: 0.1em;
  color: #76beea;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  border: 2px solid #76beea;
  padding: 11px 28px 9px;
  font-family: 'RussoOne', sans-serif;
  transition: all 0.5s;
}
@media screen and (max-width: 1600px) {
  .top-info__btn {
    padding: 11px 15px 9px;
  }
}
.top-info__btn:hover {
  color: #ffffff;
  background: #76beea;
}
@media screen and (min-width: 961px) {
  .top-info--opacity {
    background: none;
    box-shadow: none;
  }
  .top-info--opacity .top-info__logo--black {
    display: none;
  }
  .top-info--opacity .top-info__logo--white {
    display: block;
  }
  .top-info--opacity .menu__link {
    color: #ffffff;
  }
  .top-info--opacity .menu__link--active,
  .top-info--opacity .menu__item:hover .menu__link {
    color: #76beea !important;
    border-color: #76beea !important;
  }
  .top-info--opacity .top-info__phone {
    color: #ffffff;
  }
  .top-info--opacity .top-info__city {
    color: #ffffff;
  }
}
.submenu {
  display: none;
  position: absolute;
  left: -30px;
  top: 100%;
  list-style: none;
}
@media screen and (max-width: 960px) {
  .submenu {
    position: relative;
    left: auto;
    top: auto;
  }
  .submenu--open {
    display: block;
  }
}
.submenu__item {
  background-color: #0068a0;
  border-bottom: 1px solid #3386b3;
  position: relative;
}
.submenu__item:last-child {
  border-bottom: 0;
}
.submenu__link {
  font-size: 15px !important;
  color: #ffffff !important;
  text-decoration: none;
  padding: 10px 35px 10px 30px;
  display: block;
  position: relative;
  width: 350px;
  font-weight: 300 !important;
}
@media screen and (max-width: 960px) {
  .submenu__link {
    font-size: 14px !important;
    width: 100%;
  }
}
.submenu__link:after {
  content: '';
  display: block;
  width: 11px;
  height: 9px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 20px;
  background: url(../images/menu-arrow.svg);
}
.submenu__link:hover,
.submenu__link--active,
.submenu__link--open {
  background-color: #76beea;
}
.submenu__link:hover {
  color: #ffffff !important;
}
.submenu__submenu {
  display: none;
}
@media screen and (max-width: 960px) {
  .submenu__submenu {
    position: relative;
    left: auto;
    top: auto;
    padding-left: 50px;
    list-style: none;
  }
  .submenu__submenu--open {
    display: block;
  }
  .submenu__submenu .submenu__link {
    background: transparent;
  }
  .submenu__submenu .submenu__link--active,
  .submenu__submenu .submenu__link--open {
    background-color: #76beea;
  }
}
@media screen and (min-width: 961px) {
  .submenu__item:hover .submenu__submenu {
    display: block;
    position: absolute;
    left: 100%;
    top: 0;
    list-style: none;
  }
}
.social {
  display: flex;
  margin-top: 15px;
  align-content: center;
  align-items: center;
}
@media screen and (max-width: 960px) {
  .social {
    margin-bottom: 30px;
  }
}
.social__item {
  margin-right: 20px;
  fill: #ffffff;
  display: flex;
}
@media screen and (max-width: 670px) {
  .social__item {
    margin-right: 10px;
  }
}
.social__item:hover {
  fill: #76beea;
}
.social__item--inst svg {
  width: 19px;
  height: 18px;
}
@media screen and (max-width: 670px) {
  .social__item--inst svg {
    width: 15px;
    height: 15px;
  }
}
.social__item--vk svg {
  width: 23px;
  height: 13px;
}
@media screen and (max-width: 670px) {
  .social__item--vk svg {
    width: 19px;
    height: 10px;
  }
}
.social__item--fb svg {
  width: 11px;
  height: 18px;
}
@media screen and (max-width: 670px) {
  .social__item--fb svg {
    width: 9px;
    height: 15px;
  }
}
.search {
  position: relative;
}
.search__btn-for-search {
  display: block;
  width: 18px;
  height: 18px;
  background: url(../images/search-icon-white.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
  position: absolute;
  right: 0;
}
.search__form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  position: relative;
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s;
}
.search__form--open {
  opacity: 1;
  pointer-events: auto;
}
.search__input {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #ffffff;
  width: 200px;
  background-color: transparent;
  /* Firefox 19+ */
  /* Firefox 18- */
}
.search__input::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 0.6;
}
.search__input::-moz-placeholder {
  color: #ffffff;
  opacity: 0.6;
}
.search__input:-moz-placeholder {
  color: #ffffff;
  opacity: 0.6;
}
.search__input:-ms-input-placeholder {
  color: #ffffff;
  opacity: 0.6;
}
.search__input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
.search__input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
.search__input::-webkit-search-decoration,
.search__input::-webkit-search-cancel-button,
.search__input::-webkit-search-results-button,
.search__input::-webkit-search-results-decoration {
  display: none;
}
.search__search-btn {
  width: 18px;
  height: 18px;
  background: url(../images/search-icon-white.svg) no-repeat center;
  background-size: contain;
}
@media screen and (max-width: 960px) {
  .menu {
    display: none;
  }
  .menu--open {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #0a3a53;
    height: calc(100vh - 50px);
    overflow: auto;
  }
}
.menu__list {
  display: flex;
  list-style: none;
}
@media screen and (max-width: 960px) {
  .menu__list {
    flex-direction: column;
  }
}
.menu__item {
  margin-right: 35px;
  padding-top: 33px;
  padding-bottom: 35px;
  position: relative;
}
@media screen and (max-width: 1600px) {
  .menu__item {
    margin-right: 20px;
  }
}
@media screen and (min-width: 961px) {
  .menu__item:hover .menu__link {
    color: #0068a0;
    border-bottom: 2px solid #0068a0;
  }
  .menu__item:hover .submenu {
    display: block;
  }
}
@media screen and (max-width: 960px) {
  .menu__item {
    margin: 0;
    padding: 0;
  }
}
.menu__link {
  font-size: 13px;
  font-family: 'RussoOne', sans-serif;
  color: #1e1e1e !important;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.1em;
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(255, 255, 255, 0);
  cursor: pointer;
  font-weight: 300 !important;
}
@media screen and (max-width: 1100px) {
  .menu__link {
    font-size: 11px;
  }
}
@media screen and (max-width: 960px) {
  .menu__link {
    background-color: #0a3a53;
    font-size: 15px;
    color: #ffffff !important;
    width: 100%;
    padding: 10px 15px;
    display: block;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .menu__link:after {
    content: '';
    display: block;
    width: 11px;
    height: 9px;
    position: absolute;
    top: 17px;
    right: 20px;
    background: url(../images/menu-arrow.svg) no-repeat;
    background-size: contain;
  }
  .menu__link--hasSub:after {
    width: 9px;
    height: 11px;
    background-image: url(../images/menu-arrow-down.svg);
    top: 13px;
  }
}
@media screen and (min-width: 961px) {
  .menu__link:hover,
  .menu__link--active,
  .menu__link--open {
    color: #0068a0 !important;
    border-bottom: 2px solid #0068a0 !important;
  }
}
@media screen and (max-width: 960px) {
  .menu__link--active,
  .menu__link--open {
    color: #4886aa !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
  }
  .menu__link--active:after,
  .menu__link--open:after {
    background-image: url(../images/menu-arrow-top.svg);
  }
}
.footer {
  padding-top: 45px;
  padding-bottom: 45px;
  background: #1e1e1e;
  text-align: left;
}
@media screen and (max-width: 670px) {
  .footer {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.footer__main {
  margin-bottom: 70px;
  overflow: hidden;
}
@media screen and (max-width: 960px) {
  .footer__main {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 670px) {
  .footer__main {
    margin-bottom: 35px;
  }
}
.footer__item {
  display: flex;
  flex-direction: column;
}
.footer__logo {
  margin-bottom: 30px;
}
@media screen and (max-width: 670px) {
  .footer__logo {
    max-width: 85%;
    margin-bottom: 15px;
  }
}
.footer__phone {
  font-size: 25px;
  font-weight: 500;
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 15px;
}
@media screen and (max-width: 670px) {
  .footer__phone {
    font-size: 14px;
    margin-bottom: 7px;
  }
}
.footer__email {
  font-size: 14px;
  color: #0068a0;
  text-decoration: none;
  margin-bottom: 15px;
}
@media screen and (max-width: 670px) {
  .footer__email {
    font-size: 14px;
    margin-bottom: 7px;
  }
}
.footer__venue {
  font-size: 14px;
  line-height: 1.7;
  color: #ffffff;
  margin-bottom: 15px;
}
@media screen and (max-width: 670px) {
  .footer__venue {
    font-size: 14px;
    margin-bottom: 7px;
  }
}
.footer__title {
  margin-bottom: 45px;
  font-size: 16px;
  font-family: 'RussoOne', sans-serif;
  color: #ffffff;
  text-transform: uppercase;
  margin-top: 25px;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 960px) {
  .footer__title {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 670px) {
  .footer__title {
    margin-bottom: 15px;
    margin-top: 20px;
    font-size: 12px;
  }
}
.footer__another {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.2);
}
@media screen and (max-width: 670px) {
  .footer__another {
    font-size: 11px;
  }
}
.footer__another a {
  color: rgba(255, 255, 255, 0.2);
  text-decoration: none;
}
.footer__another a:hover {
  color: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 960px) {
  .footer__col-jobs {
    order: 1;
  }
}
@media screen and (max-width: 670px) {
  .footer__col-jobs {
    order: 0;
  }
}
@media screen and (max-width: 670px) {
  .footer__col-company {
    margin-top: -110px;
  }
}
.footer-menu {
  list-style: none;
}
.footer-menu__link {
  font-size: 15px;
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 17px;
  display: inline-block;
}
@media screen and (max-width: 960px) {
  .footer-menu__link {
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 670px) {
  .footer-menu__link {
    font-size: 14px;
    margin-bottom: 5px;
  }
}
.footer-menu__link--active {
  color: #76beea;
}
@media screen and (min-width: 961px) {
  .footer-menu__link:hover {
    color: #76beea;
  }
}
.footer-menu__link--btn {
  font-family: 'RussoOne', sans-serif;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 13px;
  color: #0068a0;
  padding-bottom: 5px;
  position: relative;
}
@media screen and (max-width: 670px) {
  .footer-menu__link--btn {
    font-size: 10px;
    margin-top: 3px;
  }
}
.footer-menu__link--btn:after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background: #0068a0;
  position: absolute;
  bottom: 0;
  left: 0;
}
.footer-menu__link--btn:hover:after {
  background: #76beea;
}
