.new-styles .inner {
  position: relative;
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 30px;  

  @media screen and (max-width: 670px) {
    padding: 0 15px;
  }
}
.new-styles .inner:after {
    content: '';
    display: block;
    clear: both;
}
.new-styles .inner--all {
  padding: 0;
  overflow: hidden;

  @media screen and (max-width: 960px) {
    padding: 0;
  }
  @media screen and (max-width: 670px) {
    padding: 0;
  }
}
.new-styles .inner--max {
  padding: 0 60px;

  @media screen and (max-width: 1600px) {
    padding: 0 30px;
  }
  @media screen and (max-width: 670px) {
    padding: 0 15px;
  }
}
.new-styles .inner--large {
  padding: 0 60px;
  max-width: 1920px;

  @media screen and (max-width: 1600px) {
    padding: 0 30px;
  }
  @media screen and (max-width: 670px) {
    padding: 0 15px;
  }
}
.new-styles .inner--container {
  max-width: 1340px;
  padding: 0 30px;

  @media screen and (max-width: 670px) {
    padding: 0 15px;
  }
}
.new-styles .inner--allright {
  position: relative;
  max-width: 100%;
  height: 100%;
  margin: 0 0 0 auto;
  padding: 0 0 0 25px;  
  width: calc( 1310px + (100vw - 1310px)/2 );
  overflow: hidden;

  @media screen and (max-width: 670px) {
    padding-left: 15px;
    padding-right: 40px;
  }  
}
.new-styles .inner--allright--inside {
  max-width: 1280px;
  overflow: visible;
}