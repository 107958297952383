.menu {

  @media screen and (max-width: 960px) {
    display: none;

    &--open {
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: #0a3a53;
      height: calc(100vh - 50px);
      overflow: auto;
    }
  }

  &__list {
    display: flex;
    list-style: none;

    @media screen and (max-width: 960px) {
      flex-direction: column;
    }
  }
  &__item {
    margin-right: 35px;
    padding-top: 33px;
    padding-bottom: 35px;
    position: relative;

    @media screen and (max-width: 1600px) {
      margin-right: 20px;
    }

    &:hover {
      @media screen and (min-width: 961px) {
        .menu__link {
          color: #0068a0;
          border-bottom: 2px solid #0068a0;
        }
        .submenu {
          display: block;
        }
      }
    }

    @media screen and (max-width: 960px) {
      margin: 0;
      padding: 0;
    }
  }
  &__link {
    font-size: 13px;
    font-family: 'RussoOne', sans-serif;
    color: #1e1e1e !important;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.1em;
    padding-bottom: 10px;
    border-bottom: 2px solid rgba(255,255,255,0);
    cursor: pointer;
    font-weight: 300 !important;

    @media screen and (max-width: 1100px) {
      font-size: 11px;
    }

    @media screen and (max-width: 960px) {
      background-color: #0a3a53;
      font-size: 15px;
      color: #ffffff !important;
      width: 100%;
      padding: 10px 15px;
      display: block;
      border-bottom: 1px solid rgba(255,255,255,0.2);

      &:after {
        content: '';
        display: block;
        width: 11px;
        height: 9px;
        position: absolute;
        top: 17px;
        right: 20px;
        background: url(../images/menu-arrow.svg) no-repeat;
        background-size: contain;
      }

      &--hasSub {
        &:after {
          width: 9px;
          height: 11px;
          background-image: url(../images/menu-arrow-down.svg);
          top: 13px;
        }
      }     
    }

    &:hover,
    &--active,
    &--open {
      @media screen and (min-width: 961px) {
        color: #0068a0 !important;
        border-bottom: 2px solid #0068a0 !important;
      }
    }

    &--active,
    &--open {
      @media screen and (max-width: 960px) {
        color: #4886aa !important;
        border-bottom: 1px solid rgba(255,255,255,0.2) !important;

        &:after {
          background-image: url(../images/menu-arrow-top.svg);
        }
      }
    }

    
  }


}